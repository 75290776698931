/* :root {
  --bg-navbar: #0C266A;
  --bg-primary: #0F3EB7;
  --bg-secondary: #E1E3EA;
  --bt-confirm: linear-gradient(90.22deg, #123692 1.1%, #1243C0 99.81%);
  --text-bt-confirm: #FFFFFF;
  --bt-login: linear-gradient(180deg, #F00000 0%, #FF6262 7.81%, #F00000 25.52%, #C20000 100%);
  --bt-register: linear-gradient(90deg, #FF5C00 0%, #FF6B00 100.6%);
  --text-bt-register: #FFFFFF;
  --text-bt-login: #FFFFFF;
  --bg-brand: linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9.38%, #FFA800 27.08%, #FF6B00 100%);
  --bg-label-money: #FFFFFF;
  --bg-camp: #000000;
  --text-secondary: #373737;
  --icon-bg-card: linear-gradient(180deg, #E7E7E7 0%, #FFFFFF 28.39%, #FFFFFF 89.06%);
  --bg-card: #FFFFFF;
  --bg-select: #F85900;
  --bg-floor: #D7D8DB;
  --bg-menu-floor: #000000;
  --bg-label-text: linear-gradient(90deg, #000000 0%, #DF0000 29.17%, #DF0000 72.4%, #000000 100%);
  --bt-sucess: #039000;
  --bt-danger: #F10000;
  --bg-warning: #FFE000;
  --bg-blue: #0041E8;
  --bg-orange: #F85900;
  --bg-black: #000000;
  --bg-white: #FFFFFF;
  --bg-gray: #6B6B6B;
  --bg-text-main: #000000;
  --bg-play-game: #202020;
  --bg-popup: #FFFFFF;
  --bg-floor-popup: rgba(0, 0, 0, 0.8);
  --bg-line: #D9D9D9;
} */

/* Position */
.positionAbsolute {
  position: absolute;
}

.positionFixed {
  position: fixed;
}

.positionRelative {
  position: relative;
}

.topLeft {
  top: 0;
  left: 0;
}

.topRight {
  top: 0;
  right: 0;
}

.topCenter {
  top: 50%;
}

.bottomLeft {
  bottom: 0;
  left: 0;
}

.bottomRight {
  bottom: 0;
  right: 0;
}

.bottomCenter {
  bottom: 0;
  left: 0;
}

.leftCenter {
  left: 50%;
}

.rightCenter {
  right: 50%;
}

.traslateXCenter {
  transform: translateX(-50%);
}

.traslateYCenter {
  transform: translateY(-50%);
}

.traslateCenter {
  transform: translate(-50%, -50%);
}

/* Width */
.widthFull {
  width: 100%;
}

/* Z-Index */
.zIndexLow {
  z-index: 1000;
}

.zIndexMedium {
  z-index: 1200;
}

.zIndexHight {
  z-index: 1400;
}

.zIndexLarge {
  z-index: 1600;
}

.zIndexBG {
  z-index: -1;
}

/* Text Bold */
.textBoldLow {
  font-weight: 400;
}

.textBoldMedium {
  font-weight: 500;
}

.textBoldHight {
  font-weight: 600;
}

.textBold {
  font-weight: bold !important;
}

/* Cursor */
.cursorPointer {
  cursor: pointer;
}

.cursorNotAllow {
  cursor: not-allowed;
}

/* Margin */
.componentMarginTop {
  margin-top: 50px;
}

.boxMarginTop {
  margin-top: 30px;
}

.contentMediumMarginTop {
  margin-top: 20px;
}

.contentMarginTop {
  margin-top: 10px;
}

.contentBoxMarginTop {
  margin-top: 15px;
}

.textMarginTop {
  margin-top: 5px;
}

.contentMediumSubMarginTop {
  margin-top: -20px;
}

/* Container */
.contentWidth {
  padding: 0px 12px;
}

.contentWidthFooter {
  padding: 0px 12px;
}

/* Game List */
.gameImage {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.gameImage:hover {
  top: 2px;
}

.gameNameBox {
  padding: 4px 4px;
  /* background: var(--bg-black); */
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0px 0px 10px 10px;
}

.gameNameBottom {
  bottom: 4px;
  left: 0;
}

.gameName {
  font-size: 16px;
  color: var(--bg-white);
}

.gameNameSzie {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

/* boxContent */
.formPadding {
  /* padding: "0px 20px 0px 20px"; */
}

.boxTitle {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: var(--bg-text-main);
}

.boxContentTitle {
  font-size: 20px;
  color: var(--bg-text-main);
}

/* Login */
.validateError {
  padding-top: 6px;
  font-size: 14px;
  color: #ff6877;
  padding-left: 8px;
}

.validateInfo {
  color: var(--bg-text-main);
}

/* Display */
.displayInlineBlock {
  display: inline-block;
}

.displayFlex {
  display: flex;
}

/* Flex */
.flexCenter {
  justify-content: center;
}

.flexAlignCenter {
  align-items: center;
}

/* Icon Close Size */
.iconCloseAll {
  position: relative;
  color: white;
  width: 46px !important;
  height: 100% !important;
  cursor: pointer;
}

.iconCloseAll:hover {
  top: 2px;
}

.iconBackPage,
.iconHomePage {
  color: var(--bg-text-main);
  width: 36px !important;
  height: 100% !important;
}

.iconSearchPage {
  color: var(--bg-text-main);
  width: 32px !important;
  height: 32px !important;
  padding-top: 8px;
}

.iconBackPage:hover,
.iconHomePage:hover,
.iconSearchPage:hover {
  top: 2px;
}

.pagePadding {
  padding-top: 40px;
}

.slick-prev,
.slick-next {
  z-index: 999 !important;
}

.slick-next {
  right: 25px !important;
}

.slick-prev {
  left: 10px !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 36px !important;
}

/* Scrol */
.menuPositionWidthScroll {
  width: auto;
  /* white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden; */
  /* margin-left: 8px;
  margin-right: 8px; */
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  padding-bottom: 6px;
}

.menuPositionWidthScroll::-webkit-scrollbar {
  height: 18px !important;
}

.menuPositionWidthScroll::-webkit-scrollbar-thumb {
  background: #b1b3b9;
  border-radius: 5px;
}

/* Radius */
.boxRadiusFull {
  border-radius: 100%;
}

.boxRadius {
  border-radius: 50px;
}

.boxRadiusHeight {
  border-radius: 15px;
}

.boxRadiusMedium {
  border-radius: 10px;
}

.boxRadiusLow {
  border-radius: 5px;
}

/* Back Page */
.backPage {
  background: var(--bg-card);
  padding: 6px 10px;
}

.backPagePadding {
  padding: 12px 10px;
}

.ruleBtn {
  border: 1px solid #434958 !important;
  border-radius: 5px !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: var(--bg-text-main) !important;
}

/* Color */
.clWhite {
  color: var(--bg-white) !important;
}

@media only screen and (max-width: 600px) {
  .componentMarginTop {
    margin-top: 30px;
  }

  .boxMarginTop {
    margin-top: 20px;
  }

  .contentWidth {
    padding: 0px 0px !important;
  }

  .boxTitle {
    font-size: 18px;
  }

  .boxContentTitle {
    font-size: 15px;
  }

  .contentMediumMarginTop {
    margin-top: 10px;
  }

  .iconBackPage {
    width: 30px !important;
  }

  .iconHomePage {
    width: 26px !important;
  }

  .iconSearchPage {
    width: 24px !important;
    height: 24px !important;
    padding: 3px;
  }

  .gameNameBox {
    padding: 3px 4px;
  }

  .gameName {
    font-size: 13px;
  }

  .pagePadding {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 30px !important;
  }

  .slick-next {
    right: 20px !important;
  }

  .menuPositionWidthScroll::-webkit-scrollbar {
    height: 0px !important;
  }

  .menuPositionWidthScroll::-webkit-scrollbar-thumb {
    border-radius: 0px;
  }

  .contentWidthFooter {
    padding: 0px 6px !important;
  }

  .contentMediumSubMarginTop {
    margin-top: -5px;
  } 
}