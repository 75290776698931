.imgError {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 2px solid #C5C5C5;
}

.imgLogoError {
    filter: drop-shadow(0 0 0.15rem black);
    width: 240px;
    height: 100%;
}

.btnErrorReload {
    background: #EA0000;
    color: white;
    font-size: 18px;
    padding: 10px 30px;
    border-radius: 50px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.btnErrorReload:hover {
    top: 2px;
    opacity: 0.8;
}

@media only screen and (max-width: 600px) {
    .imgLogoError {
        width: 160px;
    }

    .btnErrorReload { 
        font-size: 16px;
    }
}