/* :root {
    --bg-navbar: #0C266A;
    --bg-primary: #0F3EB7;
    --bg-secondary: #E1E3EA;
    --bt-confirm: linear-gradient(90.22deg, #123692 1.1%, #1243C0 99.81%);
    --text-bt-confirm: #FFFFFF;
    --bt-login: linear-gradient(180deg, #F00000 0%, #FF6262 7.81%, #F00000 25.52%, #C20000 100%);
    --bt-register: linear-gradient(90deg, #FF5C00 0%, #FF6B00 100.6%);
    --text-bt-register: #FFFFFF;
    --text-bt-login: #FFFFFF;
    --bg-brand: linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9.38%, #FFA800 27.08%, #FF6B00 100%);
    --bg-label-money: #FFFFFF;
    --bg-camp: #000000;
    --text-secondary: #373737;
    --icon-bg-card: linear-gradient(180deg, #E7E7E7 0%, #FFFFFF 28.39%, #FFFFFF 89.06%);
    --bg-card: #FFFFFF;
    --bg-select: #F85900;
    --bg-floor: #D7D8DB;
    --bg-menu-floor: #000000;
    --bg-label-text: linear-gradient(90deg, #000000 0%, #DF0000 29.17%, #DF0000 72.4%, #000000 100%);
    --bt-sucess: #039000;
    --bt-danger: #F10000;
    --bg-warning: #FFE000;
    --bg-blue: #0041E8;
    --bg-orange: #F85900;
    --bg-black: #000000;
    --bg-white: #FFFFFF;
    --bg-gray: #6B6B6B;    
    --bg-text-main: #000000;
    --bg-play-game: #202020;
    --bg-popup: #FFFFFF;
    --bg-floor-popup: rgba(0, 0, 0, 0.8);
    --bg-line: #D9D9D9;
} */

/* Reload Balance */
.spin {
    margin: auto;
    animation: spin 1s infinite;
}

.refresh {
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* FadeIn */
.fadeIn {
    transition: opacity 0.5s;
    opacity: 1;
}

.fadeInHide {
    opacity: 0;
    pointer-events: none;
}

/* Reload Game */
.loadContent {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    text-align: center;
    padding-top: 20px;
}

.loadContentText {
    font-size: 18px;
    margin-top: 20px;
    color: var(--bg-primary);
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid var(--bg-primary);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--bg-primary) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* SVG Animate */
.svg-box {
    display: inline-block;
    position: relative;
    width: 150px;
    transform: scale(1.3, 1.3);
    margin-top: 20px;
    margin-bottom: 10px;
}

.img-box {
    margin-top: 25px;
    margin-bottom: 0px;
}

.img-wheel-reward {
    width: 120px;
    height: auto;
}

.green-stroke {
    stroke: #7CB342;
}

.red-stroke {
    stroke: #FF6245;
}

.yellow-stroke {
    stroke: #FFC107;
}

.circular {
    /* stroke-width: 300px; */
    /* stroke-: 300px; */
}

.circular circle.path {
    stroke-dasharray: 330;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    opacity: 0.4;
    animation: 0.7s draw-circle ease-out;
}

/*------- Checkmark ---------*/
.checkmark {
    stroke-width: 6.25;
    stroke-linecap: round;
    position: absolute;
    top: 56px;
    left: 49px;
    width: 62px;
    height: 50px;
}

.checkmark path {
    animation: 1s draw-check ease-out;
}

@keyframes draw-circle {
    0% {
        stroke-dasharray: 0, 330;
        stroke-dashoffset: 0;
        opacity: 1;
    }

    80% {
        stroke-dasharray: 330, 330;
        stroke-dashoffset: 0;
        opacity: 1;
    }

    100% {
        opacity: 0.4;
    }
}

@keyframes draw-check {
    0% {
        stroke-dasharray: 49, 80;
        stroke-dashoffset: 48;
        opacity: 0;
    }

    50% {
        stroke-dasharray: 49, 80;
        stroke-dashoffset: 48;
        opacity: 1;
    }

    100% {
        stroke-dasharray: 130, 80;
        stroke-dashoffset: 48;
    }
}

/*---------- Cross ----------*/

.cross {
    stroke-width: 6.25;
    stroke-linecap: round;
    position: absolute;
    top: 54px;
    left: 54px;
    width: 40px;
    height: 40px;
}

.cross .first-line {
    animation: 0.7s draw-first-line ease-out;
}

.cross .second-line {
    animation: 0.7s draw-second-line ease-out;
}

@keyframes draw-first-line {
    0% {
        stroke-dasharray: 0, 56;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 0, 56;
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dasharray: 56, 330;
        stroke-dashoffset: 0;
    }
}

@keyframes draw-second-line {
    0% {
        stroke-dasharray: 0, 55;
        stroke-dashoffset: 1;
    }

    50% {
        stroke-dasharray: 0, 55;
        stroke-dashoffset: 1;
    }

    100% {
        stroke-dasharray: 55, 0;
        stroke-dashoffset: 70;
    }
}

.alert-sign {
    stroke-width: 6.25;
    stroke-linecap: round;
    position: absolute;
    top: 40px;
    left: 68px;
    width: 15px;
    height: 70px;
    animation: 0.5s alert-sign-bounce cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.alert-sign .dot {
    stroke: none;
    fill: #FFC107;
}

@keyframes alert-sign-bounce {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
    }
}

/* Load Spinner */
.loader {
    width: 80px;
    height: 80px;
    display: inline-block;
    position: relative;
    border-width: 6px 4px 6px 4px;
    border-style: solid dotted solid dotted;
    border-color: var(--bg-primary) rgba(255, 255, 255, 0.3) #fff rgba(151, 107, 93, 0.3);
    border-radius: 50%;
    box-sizing: border-box;
    animation: 1s spinner linear infinite;
}

.loader:before,
.loader:after {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    border: 12px solid transparent;
    border-bottom-color: #fff;
    transform: translate(-12px, 40px) rotate(-48deg);
}

.loader:after {
    border-color: var(--bg-primary) #0000 #0000 #0000;
    transform: translate(58px, 6px) rotate(-35deg);
}

.loadderCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
}

@keyframes spinner {
    100% {
        transform: rotate(360deg)
    }
}

@media only screen and (max-width: 600px) {
    .loader {
        width: 48px;
        height: 48px;
        border-width: 3px 2px 3px 2px;
    }

    .loader:before,
    .loader:after {
        border: 10px solid transparent;
        border-bottom-color: #fff;
        transform: translate(-10px, 19px) rotate(-35deg);
    }

    .loader:after {
        border-color: var(--bg-primary) #0000 #0000 #0000;
        transform: translate(32px, 3px) rotate(-35deg);
    }

    .svg-box {
        margin-top: 0px;
        transform: scale(1, 1);
        margin-bottom: 0px;
    }
}