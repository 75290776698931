/* :root {
    --bg-navbar: #0C266A;
    --bg-primary: #0F3EB7;
    --bg-secondary: #E1E3EA;
    --bt-confirm: linear-gradient(90.22deg, #123692 1.1%, #1243C0 99.81%);
    --text-bt-confirm: #FFFFFF;
    --bt-login: linear-gradient(180deg, #F00000 0%, #FF6262 7.81%, #F00000 25.52%, #C20000 100%);
    --bt-register: linear-gradient(90deg, #FF5C00 0%, #FF6B00 100.6%);
    --text-bt-register: #FFFFFF;
    --text-bt-login: #FFFFFF;
    --bg-brand: linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9.38%, #FFA800 27.08%, #FF6B00 100%);
    --bg-label-money: #FFFFFF;
    --bg-camp: #000000;
    --text-secondary: #373737;
    --icon-bg-card: linear-gradient(180deg, #E7E7E7 0%, #FFFFFF 28.39%, #FFFFFF 89.06%);
    --bg-card: #FFFFFF;
    --bg-select: #F85900;
    --bg-floor: #D7D8DB;
    --bg-menu-floor: #000000;
    --bg-label-text: linear-gradient(90deg, #000000 0%, #DF0000 29.17%, #DF0000 72.4%, #000000 100%);
    --bt-sucess: #039000;
    --bt-danger: #F10000;
    --bg-warning: #FFE000;
    --bg-blue: #0041E8;
    --bg-orange: #F85900;
    --bg-black: #000000;
    --bg-white: #FFFFFF;
    --bg-gray: #6B6B6B;    
    --bg-text-main: #000000;
    --bg-play-game: #202020;
    --bg-popup: #FFFFFF;
    --bg-floor-popup: rgba(0, 0, 0, 0.8);
    --bg-line: #D9D9D9;
} */

.contactTitle {
    font-size: 24px;
    color: var(--bg-text-main);
}

.contactTitleSub {
    font-size: 18px;
    color: var(--bg-text-main);
}

.contactMenuList {
    background: var(--bg-card);
    border-radius: 10px;
    color: var(--bg-text-main);
    padding: 16px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: 1px solid var(--bg-secondary);
    margin-bottom: 15px;
}

.contactMenuList:hover {
    top: 2px;
}

.contactMenuListText {
    font-size: 18px;
}

.contactMenuListImg {
    width: 100%;
    height: auto;
    max-width: 64px;
}

/* Responsive */
@media only screen and (max-width: 600px) {
    .contactMenuListImg {
        max-width: 42px;
    }
    .contactMenuListText {
        font-size: 16px;
    }
    .contactMenuList {
        padding: 10px 10px 6px 10px;
    }
    .contactTitle {
        font-size: 18px;
    }
    .contactTitleSub {
        font-size: 16px;
    }
}
