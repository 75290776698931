/* :root {
    --bg-navbar: #0C266A;
    --bg-primary: #0F3EB7;
    --bg-secondary: #E1E3EA;
    --bt-confirm: linear-gradient(90.22deg, #123692 1.1%, #1243C0 99.81%);
    --text-bt-confirm: #FFFFFF;
    --bt-login: linear-gradient(180deg, #F00000 0%, #FF6262 7.81%, #F00000 25.52%, #C20000 100%);
    --bt-register: linear-gradient(90deg, #FF5C00 0%, #FF6B00 100.6%);
    --text-bt-register: #FFFFFF;
    --text-bt-login: #FFFFFF;
    --bg-brand: linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9.38%, #FFA800 27.08%, #FF6B00 100%);
    --bg-label-money: #FFFFFF;
    --bg-camp: #000000;
    --text-secondary: #373737;
    --icon-bg-card: linear-gradient(180deg, #E7E7E7 0%, #FFFFFF 28.39%, #FFFFFF 89.06%);
    --bg-card: #FFFFFF;
    --bg-select: #F85900;
    --bg-floor: #D7D8DB;
    --bg-menu-floor: #000000;
    --bg-label-text: linear-gradient(90deg, #000000 0%, #DF0000 29.17%, #DF0000 72.4%, #000000 100%);
    --bt-sucess: #039000;
    --bt-danger: #F10000;
    --bg-warning: #FFE000;
    --bg-blue: #0041E8;
    --bg-orange: #F85900;
    --bg-black: #000000;
    --bg-white: #FFFFFF;
    --bg-gray: #6B6B6B;    
    --bg-text-main: #000000;
    --bg-play-game: #202020;
    --bg-popup: #FFFFFF;
    --bg-floor-popup: rgba(0, 0, 0, 0.8);
    --bg-line: #D9D9D9;
} */

/* BTN Action */
.btnHover {
    position: relative;
}

.btnHover:hover {
    top: 2px;
}

.btnRadius {
    border-radius: 50px;
}

.btnRadiusMedium {
    border-radius: 10px;
}

.btnRadiusLow {
    border-radius: 5px;
}

.btnWidthLow {
    width: 140px;
}

.btnWidthMedium {
    width: 200px;
}

.btnWidthHight {
    width: 260px;
}

.btnWidthLarge {
    width: 320px;
}

.btnWidthFull {
    width: 100%;
}

.btnActionPosition {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-60%);
}

/* BTN Opcity */
.btnOpcityFull {
    opacity: 1 !important;
}

/* BTN Style */
.btn {
    display: inline-block;
    /* font-weight: 400; */
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 10px 0px;
    font-size: 18px;
    line-height: 1.5;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: 0px;
    color: var(--bg-white);
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
}

.btnShowdowNone {
    box-shadow: none !important;
}

.btnPaddingUnset {
    padding: 0px;
}

.btnPaddingMore {
    padding: 4px 0px;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btnFilter {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.btnLogin {
    color: var(--bg-white);
    background: var(--bt-login);
}

.btnRegister {
    color: var(--bg-white);
    background: var(--bt-register);
}

.btnConfirm {
    color: var(--text-bt-confirm);
    background: var(--bt-confirm);
}

.btnSuccess {
    color: var(--bg-white);
    background: var(--bt-sucess);
}

.btnDanger {
    color: var(--bg-white);
    background: var(--bt-danger);
}

.btnPrimary {
    color: var(--bg-white) !important;
    background: var(--bg-primary) !important;
}

.btnBlue {
    color: var(--bg-white);
    background: var(--bg-blue);
}

.btnOutLineWhite {
    border: 2px solid var(--bg-text-main) !important;
    background: unset;
    color: var(--bg-text-main);
}

.btnNavbar {
    background: var(--bg-navbar);
    color: var(--bg-white);
}

.btnReqBank {
    font-size: 16px;
    color: var(--bg-white);
    background: var(--bt-confirm);
}

.btnSelectPromotion {
    font-size: 13px;
    padding: 5px;
    color: var(--bg-white);
    background: var(--bg-blue);
}

.btnCancelPromotion {
    font-size: 13px;
    padding: 5px;
    color: var(--bg-white);
    background: var(--bt-danger);
}

.btnRule {
    font-size: 13px;
    padding: 5px;
    color: var(--bg-black);
    background: var(--bt-confirm);
}

.btnBorderLine {
    border: 2px solid var(--bg-line) !important;
}

.btnLogin:hover,
.btnRegister:hover,
.btnConfirm:hover,
.btnSuccess:hover,
.btnDanger:hover,
.btnReqBank:hover {
    opacity: .8;
}

.btnFooter {
    text-align: center;
    padding: 12px 24px;
    border-radius: 40px;
    font-size: 18px;
    cursor: pointer;
    color: var(--bg-text-main);
    border: 2px solid var(--bg-text-main);
    background: transparent;
    position: relative;
}

.btnFooter:hover {
    top: 2px;
    background: var(--bg-white);
    color: var(--bg-black);
}

.backButton {
    color: var(--bg-text-main);
    cursor: pointer;
    width: 42px !important;
    height: 100% !important;
}

.btnSpin {
    padding: 6px 0px;
}

.btnSpinStart {
    background: linear-gradient(180deg, #D90000 0%, #FF7C7C 5.73%, #DA0000 57.81%, #930000 100%);
    border: 2px solid #FFFFFF;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.8);
    font-size: 26px;
}

.btnOutLine {
    font-size: 18px;
    font-weight: 600;
    padding: 12px;
    border-radius: 10px;
    border: 2px solid var(--bg-line); 
    color: var(--bg-white);
    background: unset;
}

.btnOutLine:hover {
    top: 2px;
}

.outLineSuccess {
    color: var(--bt-sucess) !important;
    border: 2px solid var(--bt-sucess) !important;
}

.outLineInfo {
    color: var(--bg-grey);
    border: 1px solid var(--bg-line);
}

.outLineDanger {
    color: var(--bg-black) !important;
    border: 2px solid var(--bg-line) !important;
}

.btnTextSizeMedium {
    font-size: 16px;
}

/* Responsive */
@media only screen and (max-width: 600px) {
    .btn {
        font-size: 16px;
        padding: 8px 0px;
    }

    .btnRes {
        font-size: 13px;
        padding: 4px 0px;
        width: 90px !important;
    }

    .btnOutLineWhite {
        border: 1px solid var(--bg-white);
    }

    .btnWidthMedium {
        width: 160px;
    }

    .btnWidthHight {
        width: 200px;
    }

    .btnFooter {
        font-size: 16px;
        border: 1px solid var(--bg-text-main);
    }

    .btnSpinStart {
        font-size: 18px;
    }

    .btnSpin {
        padding: 5px 0px;
        font-size: 14px;
    }

    .btnOutLine {
        font-size: 16px;
        padding: 10px;
    }
}