.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.relative {
  position: relative;
}

.progressiveImage {
  width: 100%;
}